import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentLangs: {
      lang: 'en',
      label: 'En',
      id: 1,
    },
    langs: [
      {
        lang: 'ua',
        label: 'UA',
        id: 0,
      },
      {
        lang: 'en',
        label: 'EN',
        id: 1,
      }
    ],
    userTheme: null,
    openMenu: false,
    visibleStage: [],
    currentStage: null,
    stages: ['why', 'structrue', 'partners', 'expertise', 'clients', 'wins'],
    currentPage: '',
  },
  getters: {
    getCurrentLangs(state) {
      return state.currentLangs;
    },

    getLangs(state) {
      return state.langs;
    },
    getUserTheme(state) {
      return state.userTheme;
    },
    getOpenMenu(state) {
      return state.openMenu;
    },
    getVisibleStage(state) {
      return state.visibleStage;
    },
    getCurrentStage(state) {
      return state.currentStage;
    },
    getStages(state) {
      return state.stages;
    },
    getCurrentPage(state) {
      return state.currentPage;
    },
  },
  mutations: {
    setCurrentLangs(state, newLangId) {
      state.currentLangs = state.langs.find((item) => item.id === newLangId);
    },
    setOpenMenu(state) {
      state.openMenu = !state.openMenu;
    },
    setUserTheme(state, val) {
      state.userTheme = val;
    },
    setVisibleStage(state, value) {
      // if (!state.visibleStage.some((item) => item === value)) {
      state.visibleStage.push(value);
      // }
    },
    setCurrentStage(state, value) {
      state.currentStage = state.stages.find((stage) => stage === value);
    },
    setCurrentPage(state, value) {
      state.currentPage = value;
    },
  },
  actions: {
    setCurrentLangs(state, data) {
      state.commit('setCurrentLangs', data);
    },
    setOpenMenu(state, data) {
      state.commit('setOpenMenu', data);
    },
    setUserTheme(state, data) {
      state.commit('setUserTheme', data);
    },
    setCurrentPage(state, data) {
      state.commit('setCurrentPage', data);
    }
  },
  modules: {},
});
