<template>
  <div id="app" v-bind:class="[{ iosActive: ios }]">
    <router-view/>

    <Cookies/>

    <div class="custom-cursor">
      <div
        id="cursor-big"
        class="custom-cursor__ball custom-cursor__ball--big"
      ></div>
    </div>

  </div>
</template>

<script>
import {mapMutations, mapGetters} from "vuex";
import {TweenMax} from "gsap/TweenMax";

export default {
  components: {
    // Preloader,
    Cookies: () => import('@/components/Cookies'),

  },
  data() {
    return {
      ios: false,
      preloading: true,
      loaded: false,
    };
  },

  watch: {
    $route(to, from) {
      gtag('event', 'navigation', {'event_category': 'from: ' + from.path + ' || to: ' + to.path})
    }
  },
  methods: {
    ...mapMutations(["setUserTheme"]),
    setLoaded() {
      if (sessionStorage.getItem("loaded")) {
        this.loaded = JSON.parse(sessionStorage.getItem("loaded"));
      } else {
        setTimeout(() => {
          sessionStorage.setItem("loaded", true);
          this.loaded = JSON.parse(sessionStorage.getItem("loaded"));
        }, 5000);
      }
    },
    handlePreloading() {
      this.preloading = false;
    },
    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      this.setUserTheme(theme);
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      }

      return "light-theme";
    },
  },
  updated() {
    if (JSON.parse(sessionStorage.getItem("loaded"))) {
      const cursorBig = document.getElementById("cursor-big");
      const cursorWrapper = document.querySelector(".custom-cursor");
      const links = document.getElementsByClassName(".section__item--link");
      const withClassHover = document.getElementsByClassName("cursor-hover");
      const withBiggesHover = document.getElementsByClassName(
        "biggest-cursor-hover"
      );
      const withBigHover = [...withBiggesHover];
      const withHover = [...links, ...withClassHover];

      function onMouseMove(e) {
        TweenMax.to(cursorBig, 0, {
          x: e.clientX,
          y: e.clientY,
        });
      }

      function onMouseHover() {
        TweenMax.to(cursorBig, 0.3, {
          scale: 2.2,
        });
      }

      function onMouseBigHover() {
        TweenMax.to(cursorBig, 0.3, {
          scale: 5,
        });
      }

      function onMouseHoverOut() {
        TweenMax.to(cursorBig, 0.3, {
          scale: 1,
        });
      }

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mousedown", onMouseHover);
      document.addEventListener("mouseup", onMouseHoverOut);
      document.addEventListener("mouseenter", () => {
        TweenMax.to(cursorWrapper, 0, {
          opacity: 1,
        });
      });
      document.addEventListener("mouseleave", () => {
        TweenMax.to(cursorWrapper, 0, {
          opacity: 0,
        });
      });
      withHover.forEach((element) => {
        element.addEventListener("mouseover", onMouseHover);
        element.addEventListener("mouseout", onMouseHoverOut);
      });
      withBigHover.forEach((element) => {
        element.addEventListener("mouseover", onMouseBigHover);
        element.addEventListener("mouseout", onMouseHoverOut);
      });
    }
  },
  created() {
    this.setLoaded();
  },
  mounted() {
    // document.getElementById('preloader').style.display = 'none'

    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      this.ios = true;
    }
    if (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)) {
      this.ios = true;
    }

    setTimeout(() => {
      this.preloading = false;
    }, 5000);

    if (!localStorage.getItem("user-theme")) {
      const initUserTheme = this.getMediaPreference();
      this.setTheme(initUserTheme);
    } else {
      this.setTheme(localStorage.getItem("user-theme"));
    }

    setTimeout(() => {
      const cursorBig = document.getElementById("cursor-big");
      const cursorWrapper = document.querySelector(".custom-cursor");
      const links = document.getElementsByClassName(".section__item--link");
      const withClassHover = document.getElementsByClassName("cursor-hover");
      const withBiggesHover = document.getElementsByClassName(
        "biggest-cursor-hover"
      );
      const withBigHover = [...withBiggesHover];
      const withHover = [...links, ...withClassHover];

      function onMouseMove(e) {
        TweenMax.to(cursorBig, 0, {
          x: e.clientX,
          y: e.clientY,
        });
      }

      function onMouseHover() {
        TweenMax.to(cursorBig, 0.3, {
          scale: 2.2,
        });
      }

      function onMouseBigHover() {
        TweenMax.to(cursorBig, 0.3, {
          scale: 5,
        });
      }

      function onMouseHoverOut() {
        TweenMax.to(cursorBig, 0.3, {
          scale: 1,
        });
      }

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mousedown", onMouseHover);
      document.addEventListener("mouseup", onMouseHoverOut);
      document.addEventListener("mouseenter", () => {
        TweenMax.to(cursorWrapper, 0, {
          opacity: 1,
        });
      });
      document.addEventListener("mouseleave", () => {
        TweenMax.to(cursorWrapper, 0, {
          opacity: 0,
        });
      });
      withHover.forEach((element) => {
        element.addEventListener("mouseover", onMouseHover);
        element.addEventListener("mouseout", onMouseHoverOut);
      });
      withBigHover.forEach((element) => {
        element.addEventListener("mouseover", onMouseBigHover);
        element.addEventListener("mouseout", onMouseHoverOut);
      });
    }, 6000);
  },
};
</script>

<style lang="scss">
@import url(assets/style/_reset.scss);
@import "@/assets/style/_fonts.scss";

#onetrust-consent-sdk {
  font-family: "Montserrat" !important;
  font-weight: normal !important;

  z-index: 666;
  position: relative;
}

strong {
  font-weight: 500;
}

body {
  background-color: var(--background-color-primary);

  &:not(.scroll) {

    &::-moz-selection {
      color: none;
      background: none;
    }

    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
  }

}

.iosActive {
  // .iosMenuHeader {
  //   padding-bottom: 20px;
  // }
  .iosTitle {
    &.type1 {
      font-family: "Montserrat" !important;
      font-style: normal !important;
      font-weight: 400 !important;

      @media screen and (max-width: 768px) {
        font-size: 56px !important;
      }

      @media screen and (max-width: 590px) {
        font-size: 40px !important;
      }

      .text-wrapper {
        .letters {
          .letter {
            &.strong {
              font-style: normal !important;
              font-family: "Montserrat" !important;
              font-weight: 400 !important;
            }
          }
        }
      }
    }

    &.type2 {
      font-family: "Montserrat" !important;
      font-style: normal !important;
      font-weight: 400 !important;

      .text-wrapper {
        .letters {
          .letter {
            @media screen and (max-width: 768px) {
              font-size: 40px !important;
              line-height: 52px !important;
            }

            @media screen and (max-width: 590px) {
              line-height: 40px !important;
              font-size: 28px !important;
            }

            &.strong {
              font-style: normal !important;
              font-family: "Montserrat" !important;
              font-weight: 400 !important;

              @media screen and (max-width: 768px) {
                font-size: 56px !important;
                line-height: 68px !important;
              }

              @media screen and (max-width: 590px) {
                line-height: 48px !important;
                font-size: 48px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 830px) {
  .custom-cursor {
    position: fixed;
    z-index: 99999999999999;
  }

  .custom-cursor__ball {
    position: fixed;
    top: -5px;
    left: 0;
    z-index: 99999999999999;
    background-color: rgba(41, 246, 217, 0.5);
    pointer-events: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: scale 0.5s ease;
  }
}

/* Define styles for the default root window element */
:root {
  --background-color-primary: #ffffff;
  --accent-color: #29f6d9;
  --text-primary-color: #000000;
  --text-primary-color-case: #000000;
  --text-secondary-color: #354f57;
  --element-size: 4rem;
  --decor-line-color: #cccccc;
  --background-item-color-hover: transparent;
  --background-footer-color: #e9ecef;
  --footer-text-color: #000000;
  --footer-divider-color: #000000;
  --menu-icon-color: #354f57;
  --case-allegra-color-top: #92a1ae;
  --service-border-color: #d4dadf;
  --privacy-police-bg: rgba(146, 161, 174, 0.5);
  --case-hepato-photozone-bg: #eeeeee;

  --cookeis-bg: #FFFFFF;
  --cookeis-color-text: #354F57;
  --cookeis-color-text-message: #354F57;

}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --background-color-primary: #000000;
  --accent-color: #29f6d9;
  --text-primary-color-case: white;
  --text-primary-color: #ddd;
  --text-secondary-color: #d4dadf;
  --decor-line-color: #92a1ae;
  --background-item-color-hover: #ffffff;
  --background-footer-color: #000000;
  --footer-text-color: #ffffff;
  --footer-divider-color: #d4dadf;
  --menu-icon-color: #ffffff;
  --case-allegra-color-top: #92a1ae;
  --service-border-color: #92a1ae;
  --case-hepato-photozone-bg: #354f57;
  --cookeis-bg: #354F57;
  --cookeis-color-text: #92A1AE;
  --cookeis-color-text-message: #E9ECEF;
}

::selection {
  color: none;
  background: none;
}

/* For Mozilla Firefox */

/* Optional: show position indicator in red */
//::-webkit-scrollbar-thumb {
//  background: #FF0000;
//}

body {
  margin: 0;
  height: 100%;
}

p {
  color: var(--text-primary-color);
  margin-bottom: 0;
}

* {
  // cursor: none !important;
  // outline: none !important;
  @media screen and (min-width: 820px) {
    cursor: none !important;
    outline: none !important;
  }
  -webkit-tap-highlight-color: transparent;
}

* ul {
  list-style: none;
  margin: 0;
}

a {
  // text-decoration: none;
  color: var(--text-primary-color);
}

#app {
  &.policy {
    overflow: visible !important;
  }
}

#app {
  font-family: "Montserrat", "TimesNewRoman-I", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--background-color-primary);
  // color: #2c3e50;
  scrollbar-width: none;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

#app::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.visible {
  animation: visibleHeader 1s 0.8s alternate backwards;
}

.animated {
  .grow {
    animation: growsUp 2s alternate backwards;
  }
}

@keyframes visibleHeader {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes growsUp {
  0% {
    transform: translateY(40%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
